import React from "react";
import "./Section_5.css";
import i1 from "./images/תמונה1.png";
import i2 from "./images/תמונה4.png";
import i3 from "./images/תמונה3.png";
import i4 from "./images/תמונה2.png";
import i5 from "./images/תמונה5.png";
import one_place from "../../images/1place.jpg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Section_5 = () => {
  const history = useHistory();

  return (
    <div className="section_5_container">
      <div className="section_5_header">
        <span>אודות וישניה שירותי ספורט</span>
        <span>מייעוץ ותכנון עד אבזור והקמה</span>
        <img className="oneplace_img" src={one_place}></img>
      </div>
      <div className="section_5_main">
        <div className="section_5_main_first">
          <div className="text_section">
            <p>
              <span style={{ fontWeight: "700" }}>וישניה שירותי ספורט </span>
              מייבאת ומשווקת ציוד ייחודי וייעודי לענף ההתעמלות. הציוד מיוצר ע"י
              החברות המובילות בעולם ומשווק ללקוחות מכל הארץ: מאמנים, מורים,
              מדריכים, אגודות, בתי ספר, מתנ"סים ועוד. זו דרכה של החברה להביא את
              הישגי הספורט בישראל לשיאים חדשים!{" "}
            </p>
            <p style={{ fontWeight: "600" }}>
              זוכה מקום ראשון בתחרות המפיץ הטוב ביותר בעולם בשנת 2022-2023 מתוך
              81 מפיצים בעולם.{" "}
            </p>
            <span style={{ fontSize: " var(--l-font);", color: "var(--red)" }}>
              הפתרונות של וישניה:
            </span>
            <span>
              אספקת אביזרי עזר ומכשירים לענף ההתעמלות, כולל ציוד מיוחד כמו בורות
              נחיתה ומכשור אולימפי
            </span>
            <span>אספקת מזרנים ייחודיים לפעילויות ספורט שונות</span>
            <span>
              יעוץ ותכנון בהקמת אולמות התעמלות{" "}
              <button
                className="section_5_more_pro"
                onClick={() => {
                  history.push({
                    pathname: "/Projects",
                  });
                }}
              >
                {"לפרויקטים נבחרים >>"}
              </button>
            </span>
          </div>
          <div className="section_5_images">
            <img src={i4} className="section_5_image"></img>
            <img src={i5} className="section_5_image"></img>
          </div>
        </div>
        <div className="section_5_main_second">
          <div className="section_5_images">
            <img src={i1} className="section_5_image"></img>
            <img src={i2} className="section_5_image"></img>
            <img src={i3} className="section_5_image"></img>
          </div>
          <div className="text_section">
            <span style={{ fontSize: " var(--l-font);", color: "var(--red)" }}>
              ייבוא רשמי ובלעדי של Gymnova הצרפתית
            </span>
            <p>
              וישניה היא היחידה בישראל שמייבאת את מוצרי האיכות מבית Gymnova –
              מהמובילות בייצור ציוד ומכשירי התעמלות. כל הזמנה מ-Gymnova לישראל
              עוברת דרך וישניה, שמכיר את הקטלוג לאורכו ולרוחבו ויודעת להמליץ לכל
              לקוח על פתרון מושלם.
            </p>

            <p>
              לאחר שתבנו רשימת קניות מתוך{" "}
              <span
                onClick={() => {
                  window.location.href =
                    "https://it2v7.interactiv-doc.fr/html/catalogue_gymnova_en_658/";
                }}
                style={{ fontWeight: "700", color: "var(--blue)" }}
              >
                קטלוג המוצרים של Gymnova
              </span>{" "}
              צרו קשר עם וישניה לקבלת הצעה ולביצוע הזמנה מסודרת. באפשרותכם
              להזמין מוצרים בהתאמה אישית לפי דרישות מיוחדות.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section_5;
