import React, { useEffect, useRef } from "react";
import "./Section_1.css";
import {
  motion,
  useTransform,
  transform,
  useInView,
  useAnimation,
  useScroll,
} from "framer-motion";
const Bg_Img = (props) => {
  //   const section_ref = useRef(null);
  //   const { scrollYProgress, scrollY } = useScroll({
  //     target: section_ref,
  //     offset: ["0 0/5", "0.3 0.2"],
  //   });
  //   const vars = {
  //     initial: { opacity: 0.5, x: -100 },
  //     animate: {
  //       opacity: 1,
  //       x: 0,
  //       transition: { delay: 0.1 * (props.index + 1) },
  //     },
  //   };
  return (
    <div
    //   ref={section_ref}
    //   variants={vars}
    //   initial="initial"
    //   whileInView="animate"
    >
      <img alt="img" src={props.image} className="side_images_img"></img>
    </div>
  );
};

export default Bg_Img;
