import React, { useState } from "react";
import "./Projects.css";
import p1_image1 from "../Section_2/images/kiriat_uno/image1.jpeg";
import p1_image2 from "../Section_2/images/kiriat_uno/image2.jpeg";
import p1_image3 from "../Section_2/images/kiriat_uno/image3.jpeg";
import p1_image4 from "../Section_2/images/kiriat_uno/image4.jpeg";
import p1_image5 from "../Section_2/images/kiriat_uno/image5.jpeg";
import p1_image6 from "../Section_2/images/kiriat_uno/image6.jpeg";
import p1_image7 from "../Section_2/images/kiriat_uno/image7.jpeg";
import p1_image8 from "../Section_2/images/kiriat_uno/image8.jpeg";
import p1_image9 from "../Section_2/images/kiriat_uno/image9.jpeg";
import p1_image10 from "../Section_2/images/kiriat_uno/image10.jpg";
import p1_image11 from "../Section_2/images/kiriat_uno/image11.jpg";
import p1_image12 from "../Section_2/images/kiriat_uno/image12.jpg";
import p2_image1 from "../Section_2/images/hadar/image1.jpeg";
import p2_image2 from "../Section_2/images/hadar/image2.jpeg";
import p2_image3 from "../Section_2/images/hadar/image3.jpeg";
import p2_image4 from "../Section_2/images/hadar/image4.jpeg";
import p2_image5 from "../Section_2/images/hadar/image5.jpeg";
import p2_image6 from "../Section_2/images/hadar/image6.jpeg";
import p2_image7 from "../Section_2/images/hadar/image7.jpeg";
import p2_image8 from "../Section_2/images/hadar/image8.jpeg";
import p2_image9 from "../Section_2/images/hadar/image9.jpeg";
import p2_image10 from "../Section_2/images/hadar/image10.jpeg";
import p2_image11 from "../Section_2/images/hadar/image11.jpeg";
import p2_image12 from "../Section_2/images/hadar/image12.jpg";
import p2_image13 from "../Section_2/images/hadar/image13.jpg";
import p2_image14 from "../Section_2/images/hadar/image14.jpg";
import p2_image15 from "../Section_2/images/hadar/image15.jpg";
import p3_image1 from "../Section_2/images/ramat_hasharon/image1.jpeg";
import p3_image2 from "../Section_2/images/ramat_hasharon/image2.jpeg";
import p3_image3 from "../Section_2/images/ramat_hasharon/image3.jpeg";
import p3_image4 from "../Section_2/images/ramat_hasharon/image4.JPG";
import p3_image5 from "../Section_2/images/ramat_hasharon/image5.JPG";
import p3_image6 from "../Section_2/images/ramat_hasharon/image6.JPG";
import p4_image1 from "../Section_2/images/herzelia/image1.jpeg";
import p4_image2 from "../Section_2/images/herzelia/image2.jpeg";
import p4_image3 from "../Section_2/images/herzelia/image3.jpeg";
import p4_image4 from "../Section_2/images/herzelia/image4.jpeg";
import p4_image5 from "../Section_2/images/herzelia/image5.jpeg";
import p4_image6 from "../Section_2/images/herzelia/image6.jpeg";
import p4_image7 from "../Section_2/images/herzelia/image7.jpeg";
import p4_image8 from "../Section_2/images/herzelia/image8.jpeg";
import p4_image9 from "../Section_2/images/herzelia/image9.jpeg";
import p4_image10 from "../Section_2/images/herzelia/image10.jpg";
import p4_image11 from "../Section_2/images/herzelia/image11.jpg";
import p4_image12 from "../Section_2/images/herzelia/image12.jpg";
import p4_image13 from "../Section_2/images/herzelia/image13.jpg";
import p1_video1 from "../Section_2/images/kiriat_uno/video1.mp4";
import p2_video1 from "../Section_2/images/hadar/video1.mp4";

import p3_video1 from "../Section_2/images/ramat_hasharon/video1.mp4";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import * as IconSet from "react-icons/fa";

import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";

const Projects_page = () => {
  const [selected_images, set_images] = useState([]);
  const [initial, set_initial] = useState(0);
  const pro_3 = {
    name: "אולם התעמלות קריית אונו - 2019",
    dis: "פרויקט ייחודי אשר משמש את  אגודת מכבי קריית אונו פתח תקווה.",
    images: [
      p1_image1,
      p1_image2,
      p1_image3,
      p1_image4,
      p1_image5,
      p1_image6,
      p1_image7,
      p1_image8,
      p1_image9,
      p1_image10,
      p1_image11,
      p1_image12,
    ],
  };
  const pro_1 = {
    name: "מרכז הספורט הלאומי הדר יוסף - 2023",
    dis: "פרוייקט ייחודי של אולם שבמקורו היה אולם ספורט עם ציוד של ג'ימנובה משנת 1993, האולם עבר שיפוץ במשך  חודש  וחצי ונבנה מחדש עם כל האביזרים והבורות המודרניים ביותר לטובת תחרויות גביע העולם בהתעמלות קרקע ומכשירים 2023.\n כיום האולם משמש למספר אגודות וכן לסגלי נבחרות בנים ובנות ובית  הספר למאמנים.",
    images: [
      p2_image1,
      p2_image2,
      p2_image3,
      p2_image4,
      p2_image5,
      p2_image6,
      p2_image7,
      p2_image8,
      p2_image9,
      p2_image10,
      p2_image11,
      p2_image12,
      p2_image13,
      p2_image14,
      p2_image15,
    ],
  };

  const pro_4 = {
    name: "אולם התעמלות רמת השרון - 2017",
    dis: "פרוייקט ייחודי שלקח חודשיים, במסגרתו הוסב אולם כדורסל לאולם התעמלות ייחודי המכיל בור נחיתה וציוד התעמלות מודרני.",
    images: [p3_image1, p3_image2, p3_image3, p3_image4, p3_image5, p3_image6],
  };

  const pro_2 = {
    name: "אולם התעמלות הרצליה - 2022",
    dis: "פרוייקט עבור מנהל האולם אלכס שטילוב (אלוף ישראל, אלוף אירופה ומדליסט באליפות העולם).\n הפרוייקט נמשך כחודש וחצי וכיום משמש כאולם התעמלות עבור מאות ילדים וילדות.",
    images: [
      p4_image1,
      p4_image2,
      p4_image3,
      p4_image4,
      p4_image5,
      p4_image6,
      p4_image7,
      p4_image8,
      p4_image9,
      p4_image10,
      p4_image11,
      p4_image12,
      p4_image13,
    ],
  };
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth", // Optional: Add smooth scrolling behavior
  });
  const projects = [pro_1, pro_2, pro_3, pro_4];
  return (
    <div className="projects_page">
      <div className="section_2_head_line">
        <span className="sectio_2_header">הפרויקטים שלנו</span>
        <span className="section_2_header_2">
          {" "}
          מייעוץ ותכנון ועד אבזור והקמה
        </span>
      </div>{" "}
      <div className="projects_page_container">
        {projects.map((pro, index) => {
          return (
            <div key={index} className="pro_swiper_container">
              <div className="pro_header">
                {" "}
                <span>{pro.name}</span>
                <p className="pro_dis">{pro.dis}</p>
              </div>
              <div className="pro_swiper_div">
                <Swiper
                  effect={"coverflow"}
                  // grabCursor={true}
                  centeredSlides={true}
                  spaceBetween={30}
                  navigation={true}
                  initialSlide={2}
                  slidesPerView={"auto"}
                  style={{
                    height: "100%",
                    width: "calc(100% - 80px)",
                    minWidth: "360px",
                    "--swiper-navigation-color": "white",
                    "--swiper-pagination-color": "white",
                  }}
                  pagination={true}
                  modules={[Pagination, Navigation]}
                  loop={true}
                  className="pro_page_swiper"
                >
                  {pro.images.map((image, i) => {
                    return (
                      <SwiperSlide
                        key={i}
                        style={{ width: "30vw", minWidth: "350px" }}
                      >
                        <div
                          onClick={() => {
                            console.log("hello");
                            set_images(projects[index].images);
                            set_initial(i);
                          }}
                          className="project_image"
                          style={{ backgroundImage: `url(${image})` }}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          );
        })}
      </div>
      {selected_images.length > 0 ? (
        <div className="project_page_cover">
          <IconSet.FaTimes
            className="close_project_icon"
            onClick={() => {
              set_images([]);
              set_initial(0);
            }}
          ></IconSet.FaTimes>
          <Swiper
            loop={true}
            initialSlide={initial}
            navigation={true}
            modules={[Navigation]}
            className="project_swiper"
            style={{
              "--swiper-navigation-color": "white",
            }}
          >
            {selected_images.map((image, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="project_image_div_container">
                    <div
                      className="project_image_div"
                      style={{ backgroundImage: `url(${image})` }}
                    ></div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Projects_page;
