import React, { useEffect, useRef, useState } from "react";
import "./Section_2.css";
import Images_scroller from "./Images_scroller";
import p1_image1 from "../Section_2/images/kiriat_uno/image1.jpeg";
import p1_image2 from "../Section_2/images/kiriat_uno/image2.jpeg";
import p1_image3 from "../Section_2/images/kiriat_uno/image3.jpeg";
import p1_image4 from "../Section_2/images/kiriat_uno/image4.jpeg";
import p1_image5 from "../Section_2/images/kiriat_uno/image5.jpeg";
import p1_image6 from "../Section_2/images/kiriat_uno/image6.jpeg";
import p1_image7 from "../Section_2/images/kiriat_uno/image7.jpeg";
import p1_image8 from "../Section_2/images/kiriat_uno/image8.jpeg";
import p1_image9 from "../Section_2/images/kiriat_uno/image9.jpeg";
import p1_image10 from "../Section_2/images/kiriat_uno/image10.jpg";
import p1_image11 from "../Section_2/images/kiriat_uno/image11.jpg";
import p1_image12 from "../Section_2/images/kiriat_uno/image12.jpg";
import p2_image1 from "../Section_2/images/hadar/image1.jpeg";
import p2_image2 from "../Section_2/images/hadar/image2.jpeg";
import p2_image3 from "../Section_2/images/hadar/image3.jpeg";
import p2_image4 from "../Section_2/images/hadar/image4.jpeg";
import p2_image5 from "../Section_2/images/hadar/image5.jpeg";
import p2_image6 from "../Section_2/images/hadar/image6.jpeg";
import p2_image7 from "../Section_2/images/hadar/image7.jpeg";
import p2_image8 from "../Section_2/images/hadar/image8.jpeg";
import p2_image9 from "../Section_2/images/hadar/image9.jpeg";
import p2_image10 from "../Section_2/images/hadar/image10.jpeg";
import p2_image11 from "../Section_2/images/hadar/image11.jpeg";
import p2_image12 from "../Section_2/images/hadar/image12.jpg";
import p2_image13 from "../Section_2/images/hadar/image13.jpg";
import p2_image14 from "../Section_2/images/hadar/image14.jpg";
import p2_image15 from "../Section_2/images/hadar/image15.jpg";
import p3_image1 from "../Section_2/images/ramat_hasharon/image1.jpeg";
import p3_image2 from "../Section_2/images/ramat_hasharon/image2.jpeg";
import p3_image3 from "../Section_2/images/ramat_hasharon/image3.jpeg";
import p3_image4 from "../Section_2/images/ramat_hasharon/image4.JPG";
import p3_image5 from "../Section_2/images/ramat_hasharon/image5.JPG";
import p3_image6 from "../Section_2/images/ramat_hasharon/image6.JPG";
import p4_image1 from "../Section_2/images/herzelia/image1.jpeg";
import p4_image2 from "../Section_2/images/herzelia/image2.jpeg";
import p4_image3 from "../Section_2/images/herzelia/image3.jpeg";
import p4_image4 from "../Section_2/images/herzelia/image4.jpeg";
import p4_image5 from "../Section_2/images/herzelia/image5.jpeg";
import p4_image6 from "../Section_2/images/herzelia/image6.jpeg";
import p4_image7 from "../Section_2/images/herzelia/image7.jpeg";
import p4_image8 from "../Section_2/images/herzelia/image8.jpeg";
import p4_image9 from "../Section_2/images/herzelia/image9.jpeg";
import p4_image10 from "../Section_2/images/herzelia/image10.jpg";
import p4_image11 from "../Section_2/images/herzelia/image11.jpg";
import p4_image12 from "../Section_2/images/herzelia/image12.jpg";
import p4_image13 from "../Section_2/images/herzelia/image13.jpg";
import p1_video1 from "../Section_2/images/kiriat_uno/video1.mp4";
import p2_video1 from "../Section_2/images/hadar/video1.mp4";

import p3_video1 from "../Section_2/images/ramat_hasharon/video1.mp4";

import p4_video1 from "../Section_2/images/herzelia/video1.mp4";
import bg_video from "./images/WhatsApp Video 2023-12-16 at 13.40.35.mp4";
import * as IconSet from "react-icons/lu";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {
  motion,
  useTransform,
  transform,
  useInView,
  useAnimation,
  useScroll,
} from "framer-motion";
const Section_2 = (props) => {
  const [sound, set_sound] = useState(false);
  const history = useHistory();
  const pro_1 = [
    p1_image1,
    p1_image2,
    p1_image3,
    p1_image4,
    p1_image5,
    p1_image6,
    p1_image7,
    p1_image8,
    p1_image9,
    p1_image10,
    p1_image11,
    p1_image12,
  ];
  const pro_2 = [
    p2_image1,
    p2_image2,
    p2_image3,
    p2_image4,
    p2_image5,
    p2_image6,
    p2_image7,
    p2_image8,
    p2_image9,
    p2_image10,
    p2_image11,
    p2_image12,
    p2_image13,
    p2_image14,
    p2_image15,
  ];

  const pro_3 = [p3_image1, p3_image2, p3_image3];

  const pro_4 = [
    p4_image1,
    p4_image2,
    p4_image3,
    p4_image4,
    p4_image5,
    p4_image6,
    p4_image7,
    p4_image8,
    p4_image9,
    p4_image10,
    p4_image11,
    p4_image12,
    p4_image13,
  ];
  return (
    <div className="section_2_container">
      <div className="section_2_head_line">
        <span className="sectio_2_header">הפרויקטים שלנו</span>
        <span className="section_2_header_2">
          {" "}
          מייעוץ ותכנון ועד אבזור והקמה
        </span>
      </div>
      <div className="section_2_first_part_container">
        <div className="section_2_header_container">
          <p>
            וישניה שירותי ספורט גאה להיות חתומים על תכנון והקמת עשרות אולמות
            התעמלות בישראל{" "}
          </p>
          <p>
            השירות שלנו כולל תכנון האולם מקצה לקצה, רכש מכשירים בסטנדרט הטוב
            ביותר באירופה וליווי עד להשלמת ההתקנה
          </p>
        </div>
        <div
          className="section_2_bg_video"
          onClick={() => {
            set_sound(!sound);
          }}
        >
          <div className="sound_icon">
            {sound ? (
              <IconSet.LuMegaphone></IconSet.LuMegaphone>
            ) : (
              <IconSet.LuMegaphoneOff></IconSet.LuMegaphoneOff>
            )}
          </div>
          <video className="bg_video" autoPlay loop muted={!sound} playsInline>
            <source src={bg_video} type="video/mp4"></source>
          </video>
        </div>
      </div>
      <div
        // style={{ translateX: scrollYProgress }}
        className="projects_container"
      >
        <div className="projects_card">
          <Images_scroller
            handle_project={props.handle_project}
            index={0}
            images={pro_1}
          ></Images_scroller>
        </div>
        <div className="projects_card">
          <Images_scroller
            handle_project={props.handle_project}
            index={2}
            images={pro_4}
          ></Images_scroller>
        </div>
        <div className="projects_card">
          <Images_scroller
            handle_project={props.handle_project}
            index={1}
            images={pro_2}
          ></Images_scroller>
        </div>
      </div>
      <button
        className="more_projects"
        onClick={() => {
          history.push({
            pathname: "/Projects",
          });
        }}
      >
        {" לכל הפרויקטים >>"}
      </button>
    </div>
  );
};

export default Section_2;
