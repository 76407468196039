import React, { useState } from "react";
import "./Section_4.css";
import axios from "axios";
import jacky from "../Section_1/images/image4.png";
const Section_4 = () => {
  const [name, set_name] = useState("");
  const [phone, set_phone] = useState("");
  const [email, set_email] = useState("");
  const [text, set_text] = useState("");
  const [is_contact, set_is_contact] = useState(false);
  const sendemail = async (name, email, phone, text) => {
    try {
      console.log(text);
      await axios.post(
        `https://us-central1-wischnia-sports.cloudfunctions.net/sendEmail?name=${name}&email=${email}&phone=${phone}&text=${text}`,
        {}
      );

      console.log("SMS sent successfully");
    } catch (error) {
      console.error("Error sending SMS:", error);
    }
  };
  return (
    <div className="section_4_container">
      <div className="section_4_about_me">
        <div
          style={{
            backgroundColor: "var(--light-white)",
            padding: "10px",
            marginBottom: "30px",
          }}
        >
          <span
            style={{ fontSize: "calc(var(--l-font) - 6px)", fontWeight: "600" }}
          >
            ג'קי וישניה - ניסיון שעושה את ההבדל
          </span>
          <p style={{ fontSize: "calc(var(--m-font) - 2px)" }}>
            ג'קי וישניה חי ונושם את התחום כבר יותר מ-50 שנה. את החברה הקים
            ב-1999, כדי להביא לישראל ציוד ההתעמלות האיכותי, החדשני והבטיחותי
            ביותר. בזכות הידע המקצועי וההיכרות האישית עם הענף, ג'קי יודע בדיוק
            מה אתם מחפשים וצריכים.
          </p>
        </div>
        <div
          style={{
            backgroundColor: "var(--light-white)",
            padding: "10px",
            marginBottom: "30px",
          }}
        >
          <span
            style={{
              fontSize: "calc(var(--l-font) - 6px)",
              color: "var(--red)",
              fontWeight: "600",
            }}
          >
            רקורד עשיר בעולמות הספורט, הכושר וההתעמלות:
          </span>
          <div className="section_4_sentences">
            <span>נציג ישראל באיגוד ההתעמלות הבינלאומי F.I.G</span>
            <span>נציג ישראל באיגוד ההתעמלות האירופאית E.G</span>
            <span>
              {" "}
              נציג ישראל בהתאחדות האירופית של ענף התעמלות הקרקע והמכשירים
            </span>
            <span>
              {" "}
              מאמן כושר בקבוצות כדורסל בכירות ובנבחרות לאומיות לכדורסל נשים
            </span>
            <span> מאמן נבחרת ישראל לגברים בהתעמלות קרקע ומכשירים</span>{" "}
            <span> אלוף ישראל בהתעמלות קרקע ומכשירים</span>{" "}
            <span> שופט ראשי בכיר ברמה בין-לאומית</span>{" "}
            <span> פרשן ספורט בטלוויזיה</span>
            <span>מאמן כושר בקבוצות</span>
          </div>
        </div>
      </div>
      <div className="section_4_make_contact">
        <div className="section_4_jacky_container">
          <img src={jacky}></img>
        </div>
        <div className="make_contact_container">
          {!is_contact ? (
            <div className="make_contact_div">
              <span>צרו קשר - לייעוץ/הצעת מחיר</span>
              <div className="section_4_input_container">
                <input
                  type="text"
                  placeholder="שם"
                  onChange={(e) => {
                    set_name(e.target.value);
                  }}
                  value={name}
                ></input>
                <input
                  type="text"
                  required
                  placeholder="טלפון"
                  onChange={(e) => {
                    set_phone(e.target.value);
                  }}
                  value={phone}
                ></input>
                <input
                  type="email"
                  required
                  placeholder="מייל"
                  onChange={(e) => {
                    set_email(e.target.value);
                  }}
                  value={email}
                ></input>
                <textarea
                  maxLength={300}
                  placeholder="נושא הפנייה"
                  onChange={(e) => {
                    set_text(e.target.value);
                  }}
                  value={text}
                ></textarea>
              </div>
              <button
                onClick={() => {
                  sendemail(name, email, phone, text);
                  set_email("");
                  set_name("");
                  set_phone("");
                  set_text("");
                  set_is_contact(true);
                }}
              >
                שלח
              </button>
            </div>
          ) : (
            <div className="thank_you_container">
              <h1>תודה שיצרת איתנו קשר, ננסה לחזור אלייך הכי מוקדם שנוכל</h1>
            </div>
          )}
          <div className="address_container">
            <span
              onClick={() => {
                window.top.location = "tel:0544680770";
              }}
            >
              054-4680770
            </span>
            <span
              onClick={() => {
                window.top.location = "mailto:jacky.wischnia@gmail.com";
              }}
            >
              Jacky.wischnia@gmail.com
            </span>
            <span>נחמיה 7, חולון</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section_4;
