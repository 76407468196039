import React, { useState } from "react";
import "./Home_page.css";
import bg from "../Section_3/images/bg_image.jpeg";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import jacky from "../Section_1/images/jacky2.png";

// import required modules
import { Navigation } from "swiper/modules";

import * as IconSet from "react-icons/fa";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";
import Section_1 from "../Section_1/Section_1";
import Section_2 from "../Section_2/Section_2";
import Section_3 from "../Section_3/Section_3";
import Section_4 from "../Section_4/Section_4";
import Section_5 from "../Section_5/Section_5";
import head from "../../images/header.png";
import logo from "../../images/logo1.png";

const Home_Page = () => {
  const [is_menu, set_is_menu] = useState(false);
  const [images, set_images] = useState([]);
  const [initial, set_initial] = useState(0);
  const handle_project = (images_array, index) => {
    set_images(images_array);
    set_initial(index);
  };
  return (
    <div className="home_page">
      <div className="tiled_header_container">
        <header>
          <ul>
            <Link
              to="section_1"
              smooth={true}
              duration={500}
              offset={-70}
              activeClass="active"
              spy={true}
            >
              <button className="nav_button"> ראשי</button>
            </Link>
            <Link
              to="section_3"
              smooth={true}
              duration={500}
              activeClass="active"
              spy={true}
              offset={-70}
            >
              <button className="nav_button"> מוצרי ספורט</button>
            </Link>
            <Link
              to="section_2"
              smooth={true}
              duration={500}
              activeClass="active"
              spy={true}
              offset={-50}
            >
              <button className="nav_button"> פרויקטים</button>
            </Link>
            <Link
              to="section_4"
              smooth={true}
              duration={500}
              activeClass="active"
              spy={true}
              offset={-10}
            >
              <button className="nav_button">צרו קשר</button>
            </Link>
            <Link
              to="section_5"
              smooth={true}
              duration={500}
              activeClass="active"
              spy={true}
              offset={-120}
            >
              <button className="nav_button"> אודות</button>
            </Link>
          </ul>
          <IconSet.FaBars
            className="menu_icon"
            onClick={() => set_is_menu(true)}
          ></IconSet.FaBars>
          <div className="logo_name">
            <div className="header_image">
              {/* <img src={head}></img> */}
              <img src={logo} className="logo_image"></img>
            </div>
            {/* <div className="tow_lines_header">
              <span className="first_name" style={{ color: "var(--red)" }}>
                וישניה
              </span>
              <span className="second_name" style={{ color: "var(--blue)" }}>
                שירותי ספורט
              </span>
            </div>
            <div className="tow_lines_header">
              <span className="first_name" style={{ color: "var(--blue)" }}>
                Wischnia
              </span>
              <span className="second_name" style={{ color: "var(--red)" }}>
                Sports Services
              </span>
            </div> */}
          </div>
        </header>
      </div>
      <div
        className="section_3_bg_image"
        style={{ backgroundImage: `url(${bg})` }}
      ></div>
      <div className="elements_container">
        <Element name="section_1">
          <section className="section_1">
            <Section_1></Section_1>
          </section>
        </Element>
        <div className="red_div">
          <img className="jacky_image" src={jacky}></img>
        </div>

        <Element name="section_3">
          <section className="section_3">
            <Section_3></Section_3>
          </section>
        </Element>
        <Element name="section_2">
          <section className="section_2">
            <Section_2 handle_project={handle_project}></Section_2>
          </section>
        </Element>

        <Element name="section_4">
          <section className="section_4">
            <Section_4></Section_4>
          </section>
        </Element>
        <Element name="section_5">
          <section className="section_5">
            <Section_5></Section_5>
          </section>
        </Element>
        <div className="red_div_2"></div>
        <footer className="elements_footer">
          <div className="page_footer">
            <div className="our_info">
              <div className="info_data">
                <span style={{ fontSize: "20px" }}>
                  מעוניינים באתר בעיצוב אישי לניהול העסק?
                </span>
                <div className="our_logo_container">
                  <span className="our_name">Web.by</span>

                  <div className="our_logo"></div>
                </div>
              </div>
            </div>
            {/* <div className="token">
                <span>{tok_value}</span>
              </div> */}
          </div>
        </footer>
        {/* 
       
     
      */}
      </div>

      {is_menu ? (
        <div className="page_cover">
          <div
            className="close_menu"
            onClick={() => {
              set_is_menu(false);
            }}
          ></div>
          <div className="menu_page">
            <IconSet.FaTimes
              onClick={() => {
                set_is_menu(false);
              }}
              className="close_icon"
            ></IconSet.FaTimes>
            <div className="menu_container">
              <div className="menu_list">
                <Link
                  to="section_1"
                  smooth={true}
                  duration={500}
                  offset={-70}
                  // activeClass="active"
                  // spy={true}
                >
                  <button
                    className="nav_button"
                    onClick={() => {
                      set_is_menu(false);
                    }}
                  >
                    {" "}
                    ראשי
                  </button>
                </Link>
                <Link
                  to="section_3"
                  smooth={true}
                  duration={500}
                  offset={-100}

                  // activeClass="active"
                  // spy={true}
                >
                  <button
                    className="nav_button"
                    onClick={() => {
                      set_is_menu(false);
                    }}
                  >
                    {" "}
                    מוצרי ספורט
                  </button>
                </Link>
                <Link
                  to="section_2"
                  smooth={true}
                  duration={500}
                  offset={-50}

                  // activeClass="active"
                  // spy={true}
                >
                  <button
                    className="nav_button"
                    onClick={() => {
                      set_is_menu(false);
                    }}
                  >
                    {" "}
                    פרויקטים
                  </button>
                </Link>
                <Link
                  to="section_4"
                  smooth={true}
                  duration={500}
                  // activeClass="active"
                  // spy={true}
                  offset={600}
                >
                  <button
                    className="nav_button"
                    onClick={() => {
                      set_is_menu(false);
                    }}
                  >
                    {" "}
                    צרו קשר
                  </button>
                </Link>
                <Link
                  to="section_5"
                  smooth={true}
                  duration={500}
                  // activeClass="active"
                  // spy={true}
                  offset={-100}
                >
                  <button
                    className="nav_button"
                    onClick={() => {
                      set_is_menu(false);
                    }}
                  >
                    {" "}
                    אודות{" "}
                  </button>
                </Link>
                <div className="contect_icons">
                  <IconSet.FaPhoneAlt
                    onClick={() => {
                      window.top.location = "tel:0544680770";
                    }}
                    style={{ fontSize: "40px" }}
                  ></IconSet.FaPhoneAlt>
                  <IconSet.FaWhatsapp
                    onClick={() => {
                      window.top.location =
                        "https://api.whatsapp.com/send/?phone=972544680770&text=היי רציתי לברר...&type=phone_number&app_absent=0";
                    }}
                    style={{ fontSize: "40px" }}
                  ></IconSet.FaWhatsapp>
                  <IconSet.FaEnvelope
                    onClick={() => {
                      window.top.location = "mailto:jacky.wischnia@gmail.com";
                    }}
                    style={{ fontSize: "40px" }}
                  ></IconSet.FaEnvelope>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {images.length > 0 ? (
        <div className="project_page_cover">
          <IconSet.FaTimes
            className="close_project_icon"
            onClick={() => {
              set_images([]);
              set_initial(0);
            }}
          ></IconSet.FaTimes>
          <Swiper
            loop={true}
            navigation={true}
            modules={[Navigation]}
            initialSlide={initial}
            className="project_swiper"
            style={{
              "--swiper-navigation-color": "white",
            }}
          >
            {images.map((image, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="project_image_div_container">
                    <div
                      className="project_image_div"
                      style={{ backgroundImage: `url(${image})` }}
                    ></div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Home_Page;
