import React, { useEffect, useRef } from "react";
import jacky from "./images/image4.png";
import image1 from "./images/image1.png";
import image2 from "./images/image2.png";

import image3 from "./images/image3.png";
import gymnnova from "./images/gymnova.png";
import ojump from "./images/Ojump_Logo.svg.png";
import "./Section_1.css";
import {
  motion,
  useTransform,
  transform,
  useInView,
  useAnimation,
  useScroll,
} from "framer-motion";
import Bg_Img from "./Bg_Img";
const Section_1 = () => {
  // useEffect(() => {
  //   if (img_1_in_view) {
  //     more_controls.start("end");
  //   } else more_controls.start("start");
  // }, [img_1_in_view]);
  const images = [image1, image2, image3];
  const section_ref = useRef(null);
  const refs = [
    { ref_1: useRef(null) },
    { ref_2: useRef(null) },
    { ref_3: useRef(null) },
  ];
  const { scrollYProgress } = useScroll({
    target: section_ref,
    offset: ["0 0", "0.3 0.2"],
  });
  return (
    <div className="section_1_container">
      <motion.div
        className="side_images"
        ref={section_ref}
        initial="initial"
        whileInView="animate"
      >
        {images.map((image, index) => {
          return (
            <motion.div
              ref={refs[index]}
              key={index}
              variants={{
                initial: { opacity: 0.5, x: -400 },
                animate: {
                  opacity: 1,
                  x: 0,
                  transition: { delay: 0.2 + 0.1 * (index + 1) },
                },
              }}
            >
              <Bg_Img image={image}></Bg_Img>
            </motion.div>
          );
        })}
      </motion.div>

      <div className="section_1_text">
        <div className="section_1_text_header">
          <span>ייבואן רשמי ובלעדי של חברת</span>
          <span className="logos_header">
            <img src={ojump} style={{}}></img>{" "}
            {/* <span style={{ fontSize: "40px" }}> </span>{" "} */}
            <img src={gymnnova}></img>
          </span>
          <span style={{ fontSize: "calc(var(--l-font) - 6px)" }}>
            המובילות בעולם לייצור, תכנון והתקנה של מוצרים לכל ענפי ההתעמלות -
            מכשירים, קרקע, אקרובטיקה ואומנותית.{" "}
          </span>
          <span style={{ fontSize: "calc(var(--l-font) - 6px)" }}>
            פתרון התקנת אוהלים ענקיים כתחליף לאולמות
          </span>
        </div>
        <p>
          וישניה שירותי ספורט גאה לקחת חלק חשוב בקידום הספורט המקומי! כבר יותר
          מ-25 שנה שאנו מביאים לארץ את הפתרונות החדשניים ביותר בעולם, וכך עוזרים
          להכשיר את הקרקע להישגים כחול-לבן.
        </p>
        <p>
          כל מי שעוסק בספורט יודע עד כמה{" "}
          <span style={{ fontWeight: "700" }}>
            {" "}
            סביבת האימונים קריטים לביצועים ולהצלחה
          </span>{" "}
          - עד רמת המזרן. ג'קי וישניה, המייסד והבעלים, היה בעצמו אלוף ישראל
          בהתעמלות קרקע ומכשירים, אין חכם כבעל ניסיון.{" "}
        </p>
        <p>כל ציוד ההתעמלות מאושר על ידי איגוד ההתעמלות הבינלאומי FIG.</p>
      </div>
      <div className="jacky_image_container"></div>
    </div>
  );
};

export default Section_1;
