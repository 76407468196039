import "./App.css";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home_Page from "./Home_Page/Home_Page";
import Projects_page from "./Projects/Projects_page";
const Sitemap = () => {
  // Your sitemap content or component rendering logic
  return (
    <div>
      <h1>Sitemap Content</h1>
      <pre>
        {`
<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
    <url>
        <loc>https://wischnia-sports.online/</loc>
        <lastmod>2024-01-19T12:00:00+00:00</lastmod>
        <changefreq>daily</changefreq>
        <priority>1.0</priority>
    </url>
    <url>
        <loc>https://wischnia-sports.online/Projects</loc>
        <lastmod>2024-01-18T12:00:00+00:00</lastmod>
        <changefreq>weekly</changefreq>
        <priority>0.8</priority>
    </url>
</urlset>
        `}
      </pre>
    </div>
  );
};
function App() {
  return (
    <Router>
      <div className="app_page">
        <Switch>
          <Route exact path="/">
            <Home_Page></Home_Page>
          </Route>
          <Route exact path="/Projects">
            <Projects_page></Projects_page>
          </Route>
          <Route path="/sitemap.xml">
            <Sitemap />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
