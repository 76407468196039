import "./Section_2.css";
import React, { useEffect, useRef, useState } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
  motion,
  useTransform,
  transform,
  useInView,
  useAnimation,
  useScroll,
} from "framer-motion";
// import required modules
import { Pagination, Navigation } from "swiper/modules";

const Images_scroller = (props) => {
  const section_ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: section_ref,
    offset: ["0 1", "1 1"],
  });
  const test = useTransform(scrollYProgress, [0, 1], ["-50vw", "0vw"]);

  return (
    <motion.div
      ref={section_ref}
      className="image_scroller_container"
      style={{
        // opacity: window.innerWidth > 950 ? "" : scrollYProgress,
        // scale: window.innerWidth > 950 ? "" : scrollYProgress,
        x: test,
      }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
    >
      <span className="project_header">
        {props.index === 0
          ? "אולם התעמלות קריית אונו - 2019"
          : props.index === 1
          ? "מרכז הספורט הלאומי הדר יוסף - 2023"
          : "אולם התעמלות הרצליה - 2022"}
      </span>
      <Swiper
        style={{
          "--swiper-navigation-color": "white",
          "--swiper-pagination-color": "white",
          width: "100%",
        }}
        navigation={true}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        loop={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {props.images.map((image, index) => {
          return (
            <SwiperSlide key={index}>
              <div
                onClick={() => {
                  props.handle_project(props.images, index);
                }}
                className="image_slide"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </motion.div>
  );
};

export default Images_scroller;
